import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import DE from './translations/de.json'
import EN from './translations/en.json'


const i18n = createI18n({
    locale: 'DE',
    messages: {
        DE,
        EN
    }
})

createApp(App).use(createPinia()).use(router).use(i18n).mount('#app')
